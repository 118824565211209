@keyframes lds-double-ring {
	0% {
		-webkit-transform: rotate(0);
		transform        : rotate(0);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform        : rotate(360deg);
	}
}

@-webkit-keyframes lds-double-ring {
	0% {
		-webkit-transform: rotate(0);
		transform        : rotate(0);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform        : rotate(360deg);
	}
}

@keyframes lds-double-ring_reverse {
	0% {
		-webkit-transform: rotate(0);
		transform        : rotate(0);
	}

	100% {
		-webkit-transform: rotate(-360deg);
		transform        : rotate(-360deg);
	}
}

@-webkit-keyframes lds-double-ring_reverse {
	0% {
		-webkit-transform: rotate(0);
		transform        : rotate(0);
	}

	100% {
		-webkit-transform: rotate(-360deg);
		transform        : rotate(-360deg);
	}
}

.lds-css.ng-scope{
	margin: 45.5px auto;
}

.lds-double-ring {
  width : 100%;
  height: 100%
}

.lds-double-ring {
	position: relative;
}

.lds-double-ring div {
	box-sizing: border-box;
}

.lds-double-ring>div {
	position         : absolute;
	width            : 180px;
	height           : 180px;
	top              : 10px;
	left             : 10px;
	border-radius    : 50%;
	border           : 20px solid #000;
	border-color     : #158cef transparent #158cef transparent;
	-webkit-animation: lds-double-ring 1s linear infinite;
	animation        : lds-double-ring 1s linear infinite;
}

.lds-double-ring>div:nth-child(2),
.lds-double-ring>div:nth-child(4) {
	width            : 136px;
	height           : 136px;
	top              : 32px;
	left             : 32px;
	-webkit-animation: lds-double-ring_reverse 1s linear infinite;
	animation        : lds-double-ring_reverse 1s linear infinite;
}

.lds-double-ring>div:nth-child(2) {
	border-color: transparent #183899 transparent #183899;
}

.lds-double-ring>div:nth-child(3) {
	border-color: transparent;
}

.lds-double-ring>div:nth-child(3) div {
	position         : absolute;
	width            : 100%;
	height           : 100%;
	-webkit-transform: rotate(45deg);
	transform        : rotate(45deg);
}

.lds-double-ring>div:nth-child(3) div:before,
.lds-double-ring>div:nth-child(3) div:after {
	content      : "";
	display      : block;
	position     : absolute;
	width        : 20px;
	height       : 20px;
	top          : -20px;
	left         : 60px;
	background   : #158cef;
	border-radius: 50%;
	box-shadow   : 0 160px 0 0 #158cef;
}

.lds-double-ring>div:nth-child(3) div:after {
	left      : -20px;
	top       : 60px;
	box-shadow: 160px 0 0 0 #158cef;
}

.lds-double-ring>div:nth-child(4) {
	border-color: transparent;
}

.lds-double-ring>div:nth-child(4) div {
	position         : absolute;
	width            : 100%;
	height           : 100%;
	-webkit-transform: rotate(45deg);
	transform        : rotate(45deg);
}

.lds-double-ring>div:nth-child(4) div:before,
.lds-double-ring>div:nth-child(4) div:after {
	content      : "";
	display      : block;
	position     : absolute;
	width        : 20px;
	height       : 20px;
	top          : -20px;
	left         : 38px;
	background   : #183899;
	border-radius: 50%;
	box-shadow   : 0 116px 0 0 #183899;
}

.lds-double-ring>div:nth-child(4) div:after {
	left      : -20px;
	top       : 38px;
	box-shadow: 116px 0 0 0 #183899;
}

.lds-double-ring {
	width            : 59px !important;
	height           : 59px !important;
	-webkit-transform: translate(-29.5px, -29.5px) scale(0.295) translate(29.5px, 29.5px);
	transform        : translate(-29.5px, -29.5px) scale(0.295) translate(29.5px, 29.5px);
}