.list-group{
  background-color: #303030;
  border-radius: 0.25rem;
}

.item-list .list-group-item {
  cursor: pointer;
}

.item-list .list-group-item:hover {
  background-color: #444;
}